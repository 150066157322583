var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-2",attrs:{"must-sort":"","fixed-header":"","headers":_vm.headers,"items":_vm.indications,"search":_vm.options.search,"footer-props":{
    itemsPerPageOptions: [ 15, 50, 100, 200 ],
    itemsPerPageText: `${ _vm.$options.filters.capitalize(_vm.$tc('indication', 2)) } ${ _vm.$t('per page') }:`,
    showFirstLastPage: true
  },"sort-by":"indication"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"pt-3 px-3 mb-n3",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"md":"3"}},[_c('search-bar',{attrs:{"value":_vm.options.search},on:{"update:value":function($event){return _vm.$set(_vm.options, "search", $event)}}})],1),_c('v-spacer'),_c('v-col',{attrs:{"md":"2"}},[_c('source-filter',{attrs:{"value":_vm.options.filter.source},on:{"update:value":function($event){return _vm.$set(_vm.options.filter, "source", $event)}}})],1),_c('v-col',{staticClass:"shrink"},[(_vm.$auth.check({ indications: 'edit' }))?_c('v-btn',{staticClass:"mt-n5",attrs:{"color":"success","fab":"","small":""},on:{"click":function($event){return _vm.$emit('toggle-right-drawer', {
            sources: [],
            organism_categories: [],
            diagnosis_groups: []
          }, true)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fal fa-plus ")])],1):_vm._e()],1)],1)]},proxy:true},{key:"item.sources",fn:function({ item }){return _vm._l((item.sources),function(source){return _c('span',{key:source,staticClass:"mr-1",attrs:{"source":source}},[_c('source-icon',{attrs:{"source":source,"size":18}})],1)})}},{key:"item.organism_categories",fn:function({ item }){return [_vm._l((item.organism_categories.slice(0, 3)),function(category){return _c('v-chip',{key:category,staticClass:"mr-1",attrs:{"color":_vm.stringColor(category.name),"small":""},domProps:{"textContent":_vm._s(category.name)}})}),(item.organism_categories.length > 3)?_c('v-chip',{attrs:{"small":""}},[_c('v-icon',{staticClass:"ml-n2 mr-1",attrs:{"x-small":""}},[_vm._v(" fal fa-plus-circle ")]),_vm._v(" "+_vm._s(item.organism_categories.length - 3)+" more ")],1):_vm._e()]}},{key:"item.diagnosis_groups",fn:function({ item }){return [_vm._l((item.diagnosis_groups.slice(0, 3)),function(group){return _c('v-chip',{key:group,staticClass:"mr-1",attrs:{"color":_vm.stringColor(group.name),"small":""},domProps:{"textContent":_vm._s(group.name)}})}),(item.diagnosis_groups.length > 3)?_c('v-chip',{attrs:{"small":""}},[_c('v-icon',{staticClass:"ml-n2 mr-1",attrs:{"x-small":""}},[_vm._v(" fal fa-plus-circle ")]),_vm._v(" "+_vm._s(item.diagnosis_groups.length - 3)+" more ")],1):_vm._e()]}},{key:"item.actions",fn:function({ item }){return [(_vm.$auth.check({ indications: 'edit' }))?_c('v-btn',{attrs:{"small":"","ripple":"","icon":"","plain":""},on:{"click":function($event){return _vm.$emit('toggle-right-drawer', item, true)}}},[_c('v-icon',[_vm._v("fal fa-ellipsis-v")])],1):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }